
.form-check-input:checked
{
    background-color: black!important;
    border-color: black!important;
}
.form-check-input:focus{
    box-shadow:0 0 0 0.25rem rgba(0, 0, 0, 0.25)!important;
    border-color: black!important;
   
}
.item{
    a{
        color:black
    }
    a:hover{
        color:black
    }
    .isdisabled{
        color: currentColor;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
        pointer-events: none;
    }
}
.item-name,
.item-loop,
.bt-down,
.bt-up,
.btn-trash,
.btn-validate
{
    font-size: 25px;
    padding:10px; 
    color:#fff 
}


.item-name{
    font-weight: bold;
    width:125px
  
}

.bt-down,
.bt-up,
.btn-trash{
    width:50px
}
.item-loop{
    width:120px;
    input[type=number]{
        padding:5px!important
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
             opacity: 1!important;
    }
}

.player{
    position:relative;
    width:375px!important ;
    background-color: #000;
    padding:0!important;
    h1{
        font-size: 20px;
    }
    .tab_settings{
        background-color: #141414;
        min-height: 45px;
        border-bottom:1px solid #666;
        border-top:1px solid #666
    }
    .form-range{
        width:65%
    }
    i{
        color:#949494!important
    }
    .repetition{
        width: 35%;
        .input-group-text{
            padding:5px!important
        }
        .form-control{
            padding:5px!important
        }
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
             opacity: 1!important;
        }
    }
    .loop-infinite{
        border:1px solid #fff;
        border-radius: 7%;
        padding:5px

    }
    .border-top{
        border:1px solid   #2d2d2d !important;
    }
    .notification{
        font-size: 14px;
        i{
            color:#fff!important;
        }
    }
    .upload{
        i{
            color:#fff!important;
        }
    }
    .loading{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        background-color: #000;
        opacity: 0.7;
    }
    .alert{
        margin: 0!important;
        border-radius: 0!important;
        position:absolute;
        height: 70px;
        width:100%
    }
    .change-position{
        width: 100px;
        height: 35px;
        margin-top:15px;
        text-align: center;
        background: #414040;
        color: #fff;
    }
    .btn-validate i{
        color:#198754!important
    }
    .grabbable {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }
    
     /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .grabbable:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}
